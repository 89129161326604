/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "admin",
            "endpoint": "https://rhasmuw38e.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "audits",
            "endpoint": "https://1je277dmh8.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "gsca",
            "endpoint": "https://qv8kmjxy88.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        },
        {
            "name": "opendata",
            "endpoint": "https://p9tuvh54a5.execute-api.eu-west-1.amazonaws.com/staging",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:1d3b3f16-73a5-4a97-9cf8-e098506f464b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_jOZF8nwYn",
    "aws_user_pools_web_client_id": "5ecmabbic4jpi132cag9i1dvdb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "bv-myradar-adminsave155706-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-west-1",
            "search_indices": {
                "items": [
                    "placeindex635affab-staging"
                ],
                "default": "placeindex635affab-staging"
            }
        }
    }
};


export default awsmobile;
